(function () {
    'use strict';

    /*menu*/

    let menuBtn = document.querySelector('header .action-box button.open-menu');
    let searchBtn = document.querySelector('header .links-box button.open-search');

    if (menuBtn) {
        menuBtn.addEventListener('click', event => {
            if (searchBtn && searchBtn.classList.contains('open')) {
                searchBtn.classList.remove('open');
                document.querySelector('header').classList.remove('open');
                document.querySelector('.nhi-surface .side-search').classList.remove('open');
            }

            if (document.querySelector('.nhi-surface .side-menu')) {
                if (menuBtn.classList.contains('open')) {
                    menuBtn.classList.remove('open');
                    document.querySelector('header').classList.remove('open');
                    document.querySelector('.nhi-surface .side-menu').classList.remove('open');
                } else {
                    menuBtn.classList.add('open');
                    document.querySelector('header').classList.add('open');
                    document.querySelector('.nhi-surface .side-menu').classList.add('open');
                }

            } else {
                if (menuBtn.classList.contains('open')) {
                    menuBtn.classList.remove('open');
                    document.querySelector('header').classList.remove('open');
                    document.querySelector('header .main-nav').classList.remove('open');
                    document.querySelector('.main-nav nav > ul > li.with-drop.open').classList.remove('open');
                    document.querySelector('header.active-sub').classList.remove('active-sub');
                } else {
                    menuBtn.classList.add('open');
                    document.querySelector('header').classList.add('open');
                    document.querySelector('header .main-nav').classList.add('open');
                }
            }

        })
    }

    if (searchBtn) {
        searchBtn.addEventListener('click', event => {
            if (menuBtn && menuBtn.classList.contains('open')) {
                if (document.querySelector('.nhi-surface .side-menu')) {
                    menuBtn.classList.remove('open');
                    document.querySelector('header').classList.remove('open');
                    document.querySelector('.nhi-surface .side-menu').classList.remove('open');
                } else {
                    menuBtn.classList.remove('open');
                    document.querySelector('header').classList.remove('open');
                    document.querySelector('header .main-nav').classList.remove('open');
                    document.querySelector('.main-nav nav > ul > li.with-drop.open').classList.remove('open');
                    document.querySelector('header.active-sub').classList.remove('active-sub');
                }
            }

            if (searchBtn.classList.contains('open')) {
                searchBtn.classList.remove('open');
                document.querySelector('header').classList.remove('open');
                document.querySelector('.nhi-surface .side-search').classList.remove('open');
            } else {
                searchBtn.classList.add('open');
                document.querySelector('header').classList.add('open');
                document.querySelector('.nhi-surface .side-search').classList.add('open');
            }
        })
    }

    if (document.querySelectorAll('header .main-nav nav > ul > li.with-drop > a')) {
        document.querySelectorAll('header .main-nav nav > ul > li.with-drop > a').forEach(item => {
            item.addEventListener('click', event => {
                event.preventDefault();
                console.log(1212)
                if (item.closest('li.with-drop').classList.contains('open')) {
                    item.closest('li.with-drop').classList.remove('open');
                    item.closest('header').classList.remove('active-sub');

                } else {
                    let _activeElm = document.querySelector('header .main-nav nav > ul > li.with-drop.open');
                    if (_activeElm) {
                        _activeElm.classList.remove('open');
                    }

                    item.closest('li.with-drop').classList.add('open');
                    item.closest('header').classList.add('active-sub');
                }
            })
        })
    }

    if (document.querySelector('header .action-box button.prev-menu')) {
        document.querySelector('header .action-box button.prev-menu').addEventListener('click', event => {
            let _this = event.target || event.srcElement;
            let _parent = _this.closest('header');
            if (_parent.classList.contains('active-sub')) {
                _parent.classList.remove('active-sub');
                _parent.querySelector('.main-nav nav > ul > li.with-drop.open').classList.remove('open')
            }
        })
    }


    /*menu*/


    /* landscape Advantages tabs */

    const landscapeAdvantagesWrap = document.querySelector('.landscape-advantages .advantages-list');
    const landscapeAdvantagesItems = document.querySelectorAll('.landscape-advantages .advantages-list ul li');
    const landscapeAdvantagesMedia = document.querySelectorAll('.landscape-media .media-item');
    let landscapeAdvantagesStart = false;
    let currentIndex = 0;
    let intervalId;

    function isInMiddle(element) {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const elementMiddle = rect.top + rect.height / 2;
        const viewportMiddle = viewportHeight / 2;

        return Math.abs(elementMiddle - viewportMiddle) < viewportHeight * 0.25; // Adjust threshold as needed
    }

    function setActive(index) {
        landscapeAdvantagesItems.forEach((item, i) => {
            if (i === index) {
                item.classList.add('active');
                landscapeAdvantagesMedia[i].classList.add('active');
            } else {
                item.classList.remove('active');
                landscapeAdvantagesMedia[i].classList.remove('active');
            }
        });
    }

    function startLoop() {
        intervalId = setInterval(() => {
            setActive(currentIndex);
            currentIndex = (currentIndex + 1) % landscapeAdvantagesItems.length;
        }, 3000); // Change interval duration as needed
    }

    landscapeAdvantagesItems.forEach((item, index) => {
        item.addEventListener('click', function () {
            clearInterval(intervalId); // Stop the loop
            landscapeAdvantagesItems.forEach(item => item.classList.remove('active'));
            landscapeAdvantagesMedia.forEach(item => item.classList.remove('active'));
            item.classList.add('active'); // Add 'active' to the clicked item
            landscapeAdvantagesMedia[index].classList.add('active');
            currentIndex = index + 1; // Move to the next item in the loop
            startLoop(); // Restart the loop
        });
    });

    /* landscape Advantages tabs */

    /*header*/

    // keep track of previous scroll position
    let prevScrollPos = window.pageYOffset;

    window.addEventListener('scroll', function () {
        // current scroll position
        const currentScrollPos = window.pageYOffset;

        if (prevScrollPos < currentScrollPos && window.pageYOffset > 0) {
            // user has scrolled down
            document.querySelector('header').classList.add('up');
        } else {
            // user has scrolled up
            document.querySelector('header').classList.remove('up');
        }

        if (window.pageYOffset > 0) {
            document.querySelector('header').classList.add('sticky')
        } else {
            document.querySelector('header').classList.remove('sticky')
        }

        // update previous scroll position
        prevScrollPos = currentScrollPos;

        /* landscape Advantages tabs */
        if (landscapeAdvantagesWrap) {
            let last_known_scroll_position = window.scrollY;

            if (isInMiddle(landscapeAdvantagesWrap)) {
                // if (100 + landscapeAdvantagesWrap.offsetTop - window.innerHeight < last_known_scroll_position && landscapeAdvantagesWrap.offsetTop + landscapeAdvantagesWrap.offsetHeight - 100 > last_known_scroll_position) {
                if (!landscapeAdvantagesStart) {
                    landscapeAdvantagesStart = true;
                    startLoop(); // Start the loop initially
                }
            }
        }
        /* landscape Advantages tabs */
    });

    /*header*/

    /*carousel*/

    const teamCarousel = new Swiper('.carousel-box', {
        loop: true,
        slidesPerView: 'auto',
        /*breakpoints: {
            991: {
                centeredSlides: true,
            }
        }*/
    });

    const testimonialsCarousel = new Swiper('.testimonials-carousel .swiper', {
        loop: true,
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    /*carousel*/


})();
