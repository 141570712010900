function adjustOptimizingTitleHeight() {
    // Get the window width
    const windowWidth = window.innerWidth;

    // Check if the window width is more than 640px
    if (windowWidth > 640) {
        // Get the height of the <h1> element
        const h1Element = document.querySelector('.optimizing-section .section-intro');
        const h1Height = h1Element.offsetHeight;

        // Get all <li> elements
        const listItems = document.querySelectorAll('.optimizing-list .list-item');

        // Apply the <h1> height to each <li> element
        listItems.forEach(li => {
            li.style.top = `${h1Height}px`;
        });
    } else {
        // Remove the height style if window width is less than or equal to 640px
        const listItems = document.querySelectorAll('.optimizing-list .list-item');
        listItems.forEach(li => {
            li.style.top = '';
        });
    }
}

if (document.querySelector(".optimizing-section")) {
    // Run the function initially to set up the height
    adjustOptimizingTitleHeight();

// Add an event listener for window resize
    window.addEventListener('resize', adjustOptimizingTitleHeight);
}

