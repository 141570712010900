let mainHero = document.getElementById("mainHero");
if (mainHero) {
    mainHero.addEventListener("ready", () => {
        LottieInteractivity.create({
            mode: "chain",
            player: mainHero,
            actions: [
                {
                    state: 'autoplay',
                    transition: 'onComplete',
                    frames: [0, 171],
                },
                {
                    state: 'loop',
                    transition: 'onComplete',
                    frames: [172, 265],
                },
            ]
        });
    });
}

let logosLottie = document.getElementById("logosLottie");
if (logosLottie) {
    logosLottie.addEventListener("ready", () => {
        LottieInteractivity.create({
            mode: "scroll",
            player: logosLottie,
            actions: [
                {
                    visibility: [0, 1],
                    type: "seek",
                    frames: [0, 301]
                }
            ]
        });
    });
}

let landscapeLottie = document.querySelectorAll('.landscape-section .landscape-media .media-item lottie-player')
if (landscapeLottie) {
// Loop through each player
    landscapeLottie.forEach(function (player, index) {
        // Add an ID to each player based on its index
        const playerId = `landscape-${index + 1}`;
        player.setAttribute('id', playerId);

        // Initialize Lottie Interactivity for each player
        LottieInteractivity.create({
            mode: 'scroll',
            player: `#${playerId}`,
            actions: [
                {
                    visibility: [0, 1],
                    type: 'playOnce',
                    // frames: [0, 60],
                }
            ],
        });
    });
}

let optimizingLottie = document.querySelectorAll('.optimizing-list .item-content .item-data .data-media lottie-player')
if (optimizingLottie) {
// Loop through each player
    optimizingLottie.forEach(function (player, index) {
        // Add an ID to each player based on its index
        const playerId = `optimizing-${index + 1}`;
        player.setAttribute('id', playerId);

        // Initialize Lottie Interactivity for each player
        LottieInteractivity.create({
            mode: 'scroll',
            player: `#${playerId}`,
            actions: [
                {
                    visibility: [0, 1],
                    type: 'playOnce'
                }
            ],
        });
    });
}

let optimizingMiddleLottie = document.getElementById("optimizing-middle-01-02");
if (optimizingMiddleLottie) {
    optimizingMiddleLottie.addEventListener("ready", () => {
        LottieInteractivity.create({
            mode: "scroll",
            player: optimizingMiddleLottie,
            actions: [
                {
                    visibility: [0.3, 1],
                    type: 'playOnce'
                }
            ]
        });
    });
}

let approachLottie = document.querySelectorAll('.approach-list .list-item .item-img span lottie-player')
if (approachLottie) {
// Loop through each player
    approachLottie.forEach(function (player, index) {
        // Add an ID to each player based on its index
        const playerId = `approach-${index + 1}`;
        player.setAttribute('id', playerId);

        // Initialize Lottie Interactivity for each player
        LottieInteractivity.create({
            mode: 'scroll',
            player: `#${playerId}`,
            actions: [
                {
                    visibility: [0.2, 1],
                    type: 'playOnce'
                }
            ],
        });
    });
}

let processLottie = document.querySelectorAll('.process-list .list-item .item-media span lottie-player')
if (processLottie) {
// Loop through each player
    processLottie.forEach(function (player, index) {
        // Add an ID to each player based on its index
        const playerId = `process-${index + 1}`;
        player.setAttribute('id', playerId);

        // Initialize Lottie Interactivity for each player
        LottieInteractivity.create({
            mode: 'scroll',
            player: `#${playerId}`,
            actions: [
                {
                    visibility: [0.2, 1],
                    type: 'playOnce'
                }
            ],
        });
    });
}

let lineageLottie = document.getElementById("lineageLottie");
if (lineageLottie) {
    lineageLottie.addEventListener("ready", () => {
        LottieInteractivity.create({
            mode: "scroll",
            player: lineageLottie,
            actions: [
                {
                    visibility: [0, 1],
                    type: 'playOnce',
                }
            ]
        });
    });
}
